// file: Autocomplete.jsx

import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import styles from './searchTopper.module.scss';
import { className } from 'utils';

export function Autocomplete(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return (
    <header {...className('page-left-inset', 'page-left-inset-topper')}>
      <div className={styles.innerWrapper}>
        <div className={styles.row}>
          <div  className={styles.searchInputWrapper} ref={containerRef} />
        </div>
      </div>
    </header>
  );
}